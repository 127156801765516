/**
 * Generated by '@kontent-ai/model-generator@7.4.0'
 */
export const taxonomies = {
    /**
     * Capability
     */
    capability: {
        codename: 'capability',
        id: 'd864d04d-3025-40ad-8c58-075fe0703978',
        externalId: undefined,
        name: 'Capability',
        terms: {
            academic_medicine: {
                codename: 'academic_medicine',
                id: 'd14fdf60-825a-491a-95e3-947977c4370d',
                externalId: undefined,
                name: 'Academic Medicine',
                terms: {}
            },
            advertising_litigation: {
                codename: 'advertising_litigation',
                id: '08269046-05e0-401d-8f5f-019c0981bc5a',
                externalId: undefined,
                name: 'Advertising Litigation',
                terms: {}
            },
            advertising_marketing_and_media: {
                codename: 'advertising_marketing_and_media',
                id: 'c04ea071-2ffd-4542-b4a2-9a674910513c',
                externalId: undefined,
                name: 'Advertising, Marketing and Media',
                terms: {}
            },
            anti_money_laundering__bank_secrecy_act_and_sanctions_enforcement: {
                codename: 'anti_money_laundering__bank_secrecy_act_and_sanctions_enforcement',
                id: '8cbe2d19-c1f4-4cde-aeb0-457684d6021a',
                externalId: undefined,
                name: 'Anti-Money Laundering, Bank Secrecy Act and Sanctions Enforcement',
                terms: {}
            },
            antitrust_and_competition: {
                codename: 'antitrust_and_competition',
                id: '1e7ef866-a065-42b8-8889-3d3dff54160d',
                externalId: undefined,
                name: 'Antitrust and Competition',
                terms: {}
            },
            appellate: {
                codename: 'appellate',
                id: 'a4f15318-e094-4994-83b0-ab47fa323999',
                externalId: undefined,
                name: 'Appellate',
                terms: {}
            },
            artificial_intelligence: {
                codename: 'artificial_intelligence',
                id: '46db4381-b8d4-48f5-93e3-731db01dfdd1',
                externalId: undefined,
                name: 'Artificial Intelligence',
                terms: {}
            },
            auto_finance: {
                codename: 'auto_finance',
                id: 'a952f224-2030-4d9e-836f-30312e5fc774',
                externalId: undefined,
                name: 'Auto Finance',
                terms: {}
            },
            bankruptcy: {
                codename: 'bankruptcy',
                id: 'baa47b3e-dfca-479d-b1f0-7b68c697a7a3',
                externalId: undefined,
                name: 'Bankruptcy',
                terms: {}
            },
            blockchain_and_cryptocurrency: {
                codename: 'blockchain_and_cryptocurrency',
                id: '8cda0fbe-7565-4ca5-b5e1-ed6439031938',
                externalId: undefined,
                name: 'Blockchain and Cryptocurrency',
                terms: {}
            },
            business_strategy_and_consulting: {
                codename: 'business_strategy_and_consulting',
                id: '1184e4bd-853c-4fc8-b359-59288165d8bd',
                externalId: undefined,
                name: 'Business Strategy and Consulting',
                terms: {}
            },
            california_state_government_and_regulatory_policy_and_government_contracts: {
                codename: 'california_state_government_and_regulatory_policy_and_government_contracts',
                id: '70e4d1f3-eb58-4197-b6dd-cb0d1e48206c',
                externalId: undefined,
                name: 'California State Government and Regulatory Policy and Government Contracts',
                terms: {}
            },
            cannabis_and_cbd: {
                codename: 'cannabis_and_cbd',
                id: '4dce493a-eeb2-43b7-85ec-6a7def7cc91c',
                externalId: undefined,
                name: 'Cannabis and CBD',
                terms: {}
            },
            capabilities_to_serve_clients_during_covid19: {
                codename: 'capabilities_to_serve_clients_during_covid19',
                id: '64bbf354-bf0c-44af-9e7f-e4dc868ae38e',
                externalId: undefined,
                name: 'Capabilities to Serve Clients During COVID-19',
                terms: {}
            },
            capital_markets: {
                codename: 'capital_markets',
                id: 'bfc4b6e9-ad15-4d7f-9e60-b78ffb8a0472',
                externalId: undefined,
                name: 'Capital Markets',
                terms: {}
            },
            class_actions: {
                codename: 'class_actions',
                id: '469b0522-949d-4ed5-8c6f-e3e3408dc224',
                externalId: undefined,
                name: 'Class Actions',
                terms: {}
            },
            climate_change_and_clean_energy: {
                codename: 'climate_change_and_clean_energy',
                id: '89de4ca2-0bf6-4383-824c-69e7b739b335',
                externalId: undefined,
                name: 'Climate Change and Clean Energy',
                terms: {}
            },
            consumer_financial_protection_bureau: {
                codename: 'consumer_financial_protection_bureau',
                id: 'c619054f-e238-4540-9286-4d649e080a66',
                externalId: undefined,
                name: 'Consumer Financial Protection Bureau',
                terms: {}
            },
            consumer_financial_services: {
                codename: 'consumer_financial_services',
                id: '697be71d-97f7-46a1-9f7d-57b7f2853c6f',
                externalId: undefined,
                name: 'Consumer Financial Services',
                terms: {}
            },
            consumer_protection: {
                codename: 'consumer_protection',
                id: '4de6624f-7588-464f-a9bf-7f1ba3e7bc63',
                externalId: undefined,
                name: 'Consumer Protection',
                terms: {}
            },
            corporate_and_finance: {
                codename: 'corporate_and_finance',
                id: 'ebb50baf-f8e7-4432-91ed-b5666fecb320',
                externalId: undefined,
                name: 'Corporate and Finance',
                terms: {}
            },
            digital_and_technology_consulting: {
                codename: 'digital_and_technology_consulting',
                id: 'a4cc4005-1a55-4411-ad57-43dd46784e77',
                externalId: undefined,
                name: 'Digital and Technology Consulting',
                terms: {}
            },
            digital_and_technology_transactions: {
                codename: 'digital_and_technology_transactions',
                id: 'cda36836-e3fd-40a7-8008-84fda070f19f',
                externalId: undefined,
                name: 'Digital and Technology Transactions',
                terms: {}
            },
            digital_health: {
                codename: 'digital_health',
                id: '8459210b-dec3-466a-8788-dc409a1fe38d',
                externalId: undefined,
                name: 'Digital Health',
                terms: {}
            },
            digital_media_litigation: {
                codename: 'digital_media_litigation',
                id: '984542aa-caac-4ed3-9d78-899598081876',
                externalId: undefined,
                name: 'Digital Media Litigation',
                terms: {}
            },
            distressed_assets: {
                codename: 'distressed_assets',
                id: '52916676-0189-4dee-b814-058e8ac00cd9',
                externalId: undefined,
                name: 'Distressed Assets',
                terms: {}
            },
            education: {
                codename: 'education',
                id: '939b5285-645d-42f9-8f35-6cd2b8150eee',
                externalId: undefined,
                name: 'Education',
                terms: {}
            },
            employment_and_labor: {
                codename: 'employment_and_labor',
                id: '335a30ae-99c1-448a-8283-b9f125b195f4',
                externalId: undefined,
                name: 'Employment and Labor',
                terms: {}
            },
            entertainment_litigation: {
                codename: 'entertainment_litigation',
                id: '2b69c634-4ba0-48d2-92e3-e02e8bc13b31',
                externalId: undefined,
                name: 'Entertainment Litigation',
                terms: {}
            },
            environment: {
                codename: 'environment',
                id: 'd238eb90-cc33-44b2-809d-bce375c6a487',
                externalId: undefined,
                name: 'Environment',
                terms: {}
            },
            environmental_litigation: {
                codename: 'environmental_litigation',
                id: '275129f9-2159-4c98-9941-2ff64bc7475d',
                externalId: undefined,
                name: 'Environmental Litigation',
                terms: {}
            },
            estate_planning_and_personal_representation: {
                codename: 'estate_planning_and_personal_representation',
                id: '3f88cabf-c444-4cf1-a8b7-91dc162711ba',
                externalId: undefined,
                name: 'Estate Planning and Personal Representation',
                terms: {}
            },
            executive_compensation_and_employee_benefits: {
                codename: 'executive_compensation_and_employee_benefits',
                id: '745923ae-296a-4c29-9566-b02c95aff99e',
                externalId: undefined,
                name: 'Executive Compensation and Employee Benefits',
                terms: {}
            },
            false_claims_act: {
                codename: 'false_claims_act',
                id: '5d5599fa-f62a-48c6-8648-8a63ed0e9162',
                externalId: undefined,
                name: 'False Claims Act',
                terms: {}
            },
            family_office: {
                codename: 'family_office',
                id: '2d1e4839-5574-4814-9b75-e901f8eb187c',
                externalId: undefined,
                name: 'Family Office',
                terms: {}
            },
            federal_government_affairs_and_public_policy: {
                codename: 'federal_government_affairs_and_public_policy',
                id: '672f99e2-364d-4bdf-bf30-ffe18188b0cd',
                externalId: undefined,
                name: 'Federal Government Affairs and Public Policy',
                terms: {}
            },
            film__television_and_digital_content: {
                codename: 'film__television_and_digital_content',
                id: 'c0167588-62b6-4561-b480-615e79fe7b5a',
                externalId: undefined,
                name: 'Film, Television and Digital Content',
                terms: {}
            },
            financial_services_litigation_and_enforcement: {
                codename: 'financial_services_litigation_and_enforcement',
                id: 'f19c6410-cf11-422d-b1b5-4b904c4daaf7',
                externalId: undefined,
                name: 'Financial Services Litigation and Enforcement',
                terms: {}
            },
            financial_services_transactions: {
                codename: 'financial_services_transactions',
                id: '92f23497-27ad-42f7-8fd8-f3b7e9a94e68',
                externalId: undefined,
                name: 'Financial Services Transactions',
                terms: {}
            },
            fintech: {
                codename: 'fintech',
                id: '9056606d-1a1e-444a-a4d0-02617aab6d46',
                externalId: undefined,
                name: 'Fintech',
                terms: {}
            },
            gaming: {
                codename: 'gaming',
                id: '9ebe28ea-7d2d-4c39-90b3-de55994198b0',
                externalId: undefined,
                name: 'Gaming',
                terms: {}
            },
            global_payments: {
                codename: 'global_payments',
                id: '749654b4-186e-4130-9b32-8aaaeba0fead',
                externalId: undefined,
                name: 'Global Payments',
                terms: {}
            },
            government_and_regulatory: {
                codename: 'government_and_regulatory',
                id: '13c10808-3c75-4eba-9106-cd7b247e3add',
                externalId: undefined,
                name: 'Government and Regulatory',
                terms: {}
            },
            government_litigation_and_administrative_law: {
                codename: 'government_litigation_and_administrative_law',
                id: '5f7c220e-113a-4328-b7c6-60429dc1eab4',
                externalId: undefined,
                name: 'Government Litigation and Administrative Law',
                terms: {}
            },
            health_care_litigation: {
                codename: 'health_care_litigation',
                id: '666a9f4d-4436-43d6-a6b2-df230068466f',
                externalId: undefined,
                name: 'Health Care Litigation',
                terms: {}
            },
            health_care_transactions: {
                codename: 'health_care_transactions',
                id: 'e70d7d85-5e13-4c10-9293-98d5afe1f51d',
                externalId: undefined,
                name: 'Health Care Transactions',
                terms: {}
            },
            hospitality_and_leisure: {
                codename: 'hospitality_and_leisure',
                id: '259deaea-4a35-46f0-88b0-aa407f85b74a',
                externalId: undefined,
                name: 'Hospitality and Leisure',
                terms: {}
            },
            impact_investing_and_community_development: {
                codename: 'impact_investing_and_community_development',
                id: 'e7f2cd76-d035-4fda-b939-7e5d9bbc8e39',
                externalId: undefined,
                name: 'Impact Investing and Community Development',
                terms: {}
            },
            infrastructure: {
                codename: 'infrastructure',
                id: '2a13f837-9541-4e85-b2bd-d55c13938132',
                externalId: undefined,
                name: 'Infrastructure',
                terms: {}
            },
            insurance: {
                codename: 'insurance',
                id: 'd400d3b7-a86b-4322-bb54-aa507fd4a06a',
                externalId: undefined,
                name: 'Insurance',
                terms: {}
            },
            intellectual_property_protection_and_enforcement: {
                codename: 'intellectual_property_protection_and_enforcement',
                id: 'b0206115-a77b-4fad-b744-fd2f5b9b05a1',
                externalId: undefined,
                name: 'Intellectual Property Protection and Enforcement',
                terms: {}
            },
            investigations_compliance_and_white_collar_defense: {
                codename: 'investigations_compliance_and_white_collar_defense',
                id: 'c0c4f210-01bb-4312-8c33-534f9ec2dfb4',
                externalId: undefined,
                name: 'Investigations, Compliance and White Collar Defense',
                terms: {}
            },
            land_use: {
                codename: 'land_use',
                id: '6622b04b-1aca-4a62-803d-c9085fd6ae12',
                externalId: undefined,
                name: 'Land Use',
                terms: {}
            },
            life_sciences: {
                codename: 'life_sciences',
                id: 'b46bcbbf-9838-4e42-89b0-4084b40118ad',
                externalId: undefined,
                name: 'Life Sciences',
                terms: {}
            },
            litigation: {
                codename: 'litigation',
                id: 'aeddb067-695a-49ab-beca-be16454c5e08',
                externalId: undefined,
                name: 'Litigation',
                terms: {}
            },
            los_angeles_government_and_land_use: {
                codename: 'los_angeles_government_and_land_use',
                id: '110eb6ec-187a-43a8-95e9-8718878f5f8f',
                externalId: undefined,
                name: 'Los Angeles Government and Land Use',
                terms: {}
            },
            manatt_digital_and_technology: {
                codename: 'manatt_digital_and_technology',
                id: '3cf76728-682a-46ed-9084-440b54ace257',
                externalId: undefined,
                name: 'Manatt Digital and Technology',
                terms: {}
            },
            manatt_energy_and_environment: {
                codename: 'manatt_energy_and_environment',
                id: 'f2acc3b1-049c-4124-9ff8-925e797934b0',
                externalId: undefined,
                name: 'Manatt Energy and Environment',
                terms: {}
            },
            manatt_entertainment: {
                codename: 'manatt_entertainment',
                id: '3f90fe66-e69b-4f8d-8c24-542dc34db0fe',
                externalId: undefined,
                name: 'Manatt Entertainment',
                terms: {}
            },
            manatt_financial_services: {
                codename: 'manatt_financial_services',
                id: '7f61fb60-e670-4fcd-9179-7d25d4d67d30',
                externalId: undefined,
                name: 'Manatt Financial Services',
                terms: {}
            },
            manatt_health: {
                codename: 'manatt_health',
                id: '7a8fa6cc-b804-4657-9997-9ffba0a3b9bd',
                externalId: undefined,
                name: 'Manatt Health',
                terms: {}
            },
            manatt_housing_solutions: {
                codename: 'manatt_housing_solutions',
                id: '7baf4760-0d5f-4290-b6c8-10a6ef08dfab',
                externalId: undefined,
                name: 'Manatt Housing Solutions',
                terms: {}
            },
            manatt_leasing: {
                codename: 'manatt_leasing',
                id: '20ba70d8-9e9c-469d-ab14-9e68cfc1ae61',
                externalId: undefined,
                name: 'Manatt Leasing',
                terms: {}
            },
            manatt_on_health: {
                codename: 'manatt_on_health',
                id: '77d72aac-dd73-44e0-8f31-a79d67ae1b07',
                externalId: undefined,
                name: 'Manatt on Health',
                terms: {}
            },
            manatt_real_estate: {
                codename: 'manatt_real_estate',
                id: 'eb976058-986e-4240-879c-9b95fefc5b01',
                externalId: undefined,
                name: 'Manatt Real Estate',
                terms: {}
            },
            manatt_real_estate_advisors: {
                codename: 'manatt_real_estate_advisors',
                id: '8c3408d9-0df1-4ee8-9fb4-b735d741807b',
                externalId: undefined,
                name: 'Manatt Real Estate Advisors',
                terms: {}
            },
            manatt_retail_and_consumer_products: {
                codename: 'manatt_retail_and_consumer_products',
                id: '5c2f7a10-44b3-4e7b-8d1d-4865c2e21572',
                externalId: undefined,
                name: 'Manatt Retail and Consumer Products',
                terms: {}
            },
            manatt_ventures: {
                codename: 'manatt_ventures',
                id: '8ed01c61-b006-4da8-b188-906447c928f3',
                externalId: undefined,
                name: 'Manatt Ventures',
                terms: {}
            },
            manatts_recovery_team: {
                codename: 'manatts_recovery_team',
                id: '99341f59-2944-44dc-9ca2-d8a3cb154dfe',
                externalId: undefined,
                name: "Manatt's Recovery Team",
                terms: {}
            },
            mergers_and_acquisitions: {
                codename: 'mergers_and_acquisitions',
                id: '670f8aac-4a70-4d79-9b12-409dc2676416',
                externalId: undefined,
                name: 'Mergers and Acquisitions',
                terms: {}
            },
            metaverse: {
                codename: 'metaverse',
                id: 'a22c135d-7508-4edc-af87-3b0518451614',
                externalId: undefined,
                name: 'Metaverse',
                terms: {}
            },
            mortgage_banking: {
                codename: 'mortgage_banking',
                id: '87e9de35-023d-4276-8857-c0c9b193a0fc',
                externalId: undefined,
                name: 'Mortgage Banking',
                terms: {}
            },
            music: {
                codename: 'music',
                id: '5a27cc28-01f0-44bf-b2e1-792900c364c6',
                externalId: undefined,
                name: 'Music',
                terms: {}
            },
            name_image_likeness_nil_in_college_athletics: {
                codename: 'name_image_likeness_nil_in_college_athletics',
                id: '9497695d-bb90-4548-b8cc-33bb447c8ecd',
                externalId: undefined,
                name: 'Name Image Likeness (NIL) in College Athletics',
                terms: {}
            },
            national_advertising_division: {
                codename: 'national_advertising_division',
                id: '53099a59-0e83-4890-9ebd-b59a8d4d5593',
                externalId: undefined,
                name: 'National Advertising Division',
                terms: {}
            },
            new_york_city_government_policy_and_government_contracts: {
                codename: 'new_york_city_government_policy_and_government_contracts',
                id: '5733ad6b-7c2e-428d-a4e3-33055e34d950',
                externalId: undefined,
                name: 'New York City Government, Policy and Government Contracts',
                terms: {}
            },
            new_york_state_government_regulatory_policy_and_government_contracts: {
                codename: 'new_york_state_government_regulatory_policy_and_government_contracts',
                id: 'f6addd15-c8f7-4709-a7f8-260023b9983e',
                externalId: undefined,
                name: 'New York State Government, Regulatory Policy and Government Contracts',
                terms: {}
            },
            none: {
                codename: 'none',
                id: '79273f76-cda8-4d18-947d-528ebcb55336',
                externalId: undefined,
                name: 'None',
                terms: {}
            },
            political_advertising: {
                codename: 'political_advertising',
                id: '13bec2ea-e72c-4aa9-aa1e-41fe83b6f35b',
                externalId: undefined,
                name: 'Political Advertising',
                terms: {}
            },
            privacy_and_data_security: {
                codename: 'privacy_and_data_security',
                id: '1ff46241-3079-47c1-9753-5d7421166eca',
                externalId: undefined,
                name: 'Privacy and Data Security',
                terms: {}
            },
            private_equity_and_buyout: {
                codename: 'private_equity_and_buyout',
                id: '748992e0-1204-4e3c-9d55-9fed175a47e7',
                externalId: undefined,
                name: 'Private Equity and Buyout',
                terms: {}
            },
            private_wealth_and_fiduciary_disputes: {
                codename: 'private_wealth_and_fiduciary_disputes',
                id: 'd5f9dcd0-ed05-45dc-b6cb-3abd0881c930',
                externalId: undefined,
                name: 'Private Wealth and Fiduciary Disputes',
                terms: {}
            },
            pro_bono: {
                codename: 'pro_bono',
                id: '150238de-e523-41f7-997c-8330278d809a',
                externalId: undefined,
                name: 'Pro Bono',
                terms: {}
            },
            real_estate_litigation: {
                codename: 'real_estate_litigation',
                id: '72ebc05d-88c7-48c2-8a61-d2c808f29bd3',
                externalId: undefined,
                name: 'Real Estate Litigation',
                terms: {}
            },
            real_estate_transactions_and_finance: {
                codename: 'real_estate_transactions_and_finance',
                id: '3ced8c06-9761-407c-96b1-9b350cc15637',
                externalId: undefined,
                name: 'Real Estate Transactions and Finance',
                terms: {}
            },
            special_purpose_acquisition_companies_spacs: {
                codename: 'special_purpose_acquisition_companies_spacs',
                id: 'ee5a863e-e136-4bd9-8f93-ad690949b3c6',
                externalId: undefined,
                name: 'Special Purpose Acquisition Companies (SPACs)',
                terms: {}
            },
            tax: {
                codename: 'tax',
                id: 'fcf3f413-5fd3-41aa-b27a-8f8c38451ba8',
                externalId: undefined,
                name: 'Tax',
                terms: {}
            },
            tcpa_compliance_and_class_action_defense: {
                codename: 'tcpa_compliance_and_class_action_defense',
                id: '0855acd4-9e6d-4c4e-9f85-ab059dead7eb',
                externalId: undefined,
                name: 'TCPA Compliance and Class Action Defense',
                terms: {}
            },
            technology_and_intellectual_property_litigation: {
                codename: 'technology_and_intellectual_property_litigation',
                id: '785cbd51-2e96-46e7-b037-5c7e83992dc7',
                externalId: undefined,
                name: 'Technology and Intellectual Property Litigation',
                terms: {}
            },
            trademark_and_copyright_litigation: {
                codename: 'trademark_and_copyright_litigation',
                id: 'df534990-c303-4799-ae9d-7145f28d79a6',
                externalId: undefined,
                name: 'Trademark and Copyright Litigation',
                terms: {}
            },
            unmanned_aircraft: {
                codename: 'unmanned_aircraft',
                id: '5721272d-97f4-44d6-8226-7adc0c594129',
                externalId: undefined,
                name: 'Unmanned Aircraft',
                terms: {}
            },
            venture_capital__emerging_companies: {
                codename: 'venture_capital__emerging_companies',
                id: 'ea6c416b-6c9b-480a-9c40-ef41921ddf13',
                externalId: undefined,
                name: 'Venture Capital / Emerging Companies',
                terms: {}
            }
        }
    },

    /**
     * Career Category
     */
    career_category: {
        codename: 'career_category',
        id: 'b6a9af70-d54d-400b-965e-8befb6a20431',
        externalId: undefined,
        name: 'Career Category',
        terms: {
            attorneys: {
                codename: 'attorneys',
                id: '47196e3b-7cae-4b7a-9522-e119aba41ec3',
                externalId: undefined,
                name: 'Associates',
                terms: {}
            },
            staff: {
                codename: 'staff',
                id: 'f629a344-6d3e-49ed-a27c-a954ae51e269',
                externalId: undefined,
                name: 'Business Professionals',
                terms: {}
            },
            consultants_and_summer_analysts: {
                codename: 'consultants_and_summer_analysts',
                id: '4bdbf57c-9644-4f85-957c-f4f023c81092',
                externalId: undefined,
                name: 'Consultants and Summer Analysts',
                terms: {}
            },
            summer_associates: {
                codename: 'summer_associates',
                id: '078c11f0-0cdb-4963-883a-bab30dc95f81',
                externalId: undefined,
                name: 'Summer Associates',
                terms: {}
            }
        }
    },

    /**
     * Department
     */
    department: {
        codename: 'department',
        id: '2839234f-2527-40f4-a7dc-fb9a87a449a9',
        externalId: undefined,
        name: 'Department',
        terms: {
            accounting: {
                codename: 'accounting',
                id: '0c65a16d-cab4-47f7-8bbc-58dc149bd26a',
                externalId: undefined,
                name: 'Accounting',
                terms: {}
            },
            accountingpractice_management: {
                codename: 'accountingpractice_management',
                id: '994b46e9-6a31-4831-934b-f12974976ccb',
                externalId: undefined,
                name: 'Accounting/Practice Management',
                terms: {}
            },
            administration: {
                codename: 'administration',
                id: '0efed319-bf61-4874-83c5-b20e3df660e9',
                externalId: undefined,
                name: 'Administration',
                terms: {}
            },
            advertising_litigation: {
                codename: 'advertising_litigation',
                id: '0a4eba62-8319-4094-b8a0-c4e55eae700c',
                externalId: undefined,
                name: 'Advertising Litigation',
                terms: {}
            },
            advertising_marketing_and_media: {
                codename: 'advertising_marketing_and_media',
                id: 'f87230f4-d14c-46c5-82b5-d1d8336d5194',
                externalId: undefined,
                name: 'Advertising, Marketing and Media',
                terms: {}
            },
            antitrust_and_competition: {
                codename: 'antitrust_and_competition',
                id: '0da1e171-c580-4c70-af37-0d36a0bb64ba',
                externalId: undefined,
                name: 'Antitrust and Competition',
                terms: {}
            },
            appellate: {
                codename: 'appellate',
                id: '55d60fa8-af12-4fc5-8d59-c1d8afc91693',
                externalId: undefined,
                name: 'Appellate',
                terms: {}
            },
            artificial_intelligence: {
                codename: 'artificial_intelligence',
                id: '6e9e99ae-8ab9-4b5b-afc5-5e49dd2394b3',
                externalId: undefined,
                name: 'Artificial Intelligence',
                terms: {}
            },
            auto_finance: {
                codename: 'auto_finance',
                id: '9002c5d5-703d-4b99-aa16-85b18b464f10',
                externalId: undefined,
                name: 'Auto Finance',
                terms: {}
            },
            bankruptcy: {
                codename: 'bankruptcy',
                id: '5558ad60-cec6-4fa9-a03d-2b6f55e3a561',
                externalId: undefined,
                name: 'Bankruptcy',
                terms: {}
            },
            billing_and_accounts_receivable: {
                codename: 'billing_and_accounts_receivable',
                id: '5494f672-35f9-438c-b1f7-7dfde2de08c2',
                externalId: undefined,
                name: 'Billing and Accounts Receivable',
                terms: {}
            },
            blockchain_and_cryptocurrency: {
                codename: 'blockchain_and_cryptocurrency',
                id: 'c990d1d0-4193-4c1a-b756-8dfc309e3d7a',
                externalId: undefined,
                name: 'Blockchain and Cryptocurrency',
                terms: {}
            },
            business_analytics: {
                codename: 'business_analytics',
                id: '595ba094-af2d-41a3-a650-a76506e84a55',
                externalId: undefined,
                name: 'Business Analytics',
                terms: {}
            },
            business_strategy_and_consulting: {
                codename: 'business_strategy_and_consulting',
                id: 'aee582ae-ef3e-4a6e-bef1-e542ffc00c3f',
                externalId: undefined,
                name: 'Business Strategy and Consulting',
                terms: {}
            },
            california_state_government_and_regulatory_policy_and_government_contracts: {
                codename: 'california_state_government_and_regulatory_policy_and_government_contracts',
                id: '3ae8a8c4-a4d0-432b-86e5-93e7aa50b576',
                externalId: undefined,
                name: 'California State Government and Regulatory Policy and Government Contracts',
                terms: {}
            },
            cannabis_and_cbd: {
                codename: 'cannabis_and_cbd',
                id: 'f155041f-7b5d-49e0-9779-a00d26b03485',
                externalId: undefined,
                name: 'Cannabis and CBD',
                terms: {}
            },
            capabilities_to_serve_clients_during_covid19: {
                codename: 'capabilities_to_serve_clients_during_covid19',
                id: '440b3b79-76c3-48d6-87a4-f16416e0e550',
                externalId: undefined,
                name: 'Capabilities to Serve Clients During COVID-19',
                terms: {}
            },
            capital_markets: {
                codename: 'capital_markets',
                id: 'e6386f3c-f783-4c35-8084-f8aaeb26f44a',
                externalId: undefined,
                name: 'Capital Markets',
                terms: {}
            },
            class_actions: {
                codename: 'class_actions',
                id: 'fa9ea87e-43ea-4b2c-88ac-0d00b1cca41a',
                externalId: undefined,
                name: 'Class Actions',
                terms: {}
            },
            climate_change_and_clean_energy: {
                codename: 'climate_change_and_clean_energy',
                id: '55c6c93a-c43c-4656-82d9-742a07e8ff69',
                externalId: undefined,
                name: 'Climate Change and Clean Energy',
                terms: {}
            },
            communications: {
                codename: 'communications',
                id: 'bfec8898-ffc1-4eeb-9f0f-d4c1db2e5394',
                externalId: undefined,
                name: 'Communications',
                terms: {}
            },
            consumer_financial_protection_bureau_250566d: {
                codename: 'consumer_financial_protection_bureau_250566d',
                id: '250566d1-a668-4abf-820a-f9921484725d',
                externalId: undefined,
                name: 'Consumer Financial Protection Bureau',
                terms: {}
            },
            consumer_financial_protection_bureau: {
                codename: 'consumer_financial_protection_bureau',
                id: '3b418182-b9a4-420b-872a-4ddfe07d246b',
                externalId: undefined,
                name: 'Consumer Financial Services',
                terms: {}
            },
            consumer_protection: {
                codename: 'consumer_protection',
                id: 'd9cfd74a-bde3-410f-91de-390a71de7fd5',
                externalId: undefined,
                name: 'Consumer Protection',
                terms: {}
            },
            corporate_and_finance: {
                codename: 'corporate_and_finance',
                id: '6ff6bc5b-db9d-4c73-85b9-596b65773780',
                externalId: undefined,
                name: 'Corporate and Finance',
                terms: {}
            },
            digital_and_technology_consulting: {
                codename: 'digital_and_technology_consulting',
                id: '54c1cb6b-bf6e-4e79-8575-addeffd85d87',
                externalId: undefined,
                name: 'Digital and Technology Consulting',
                terms: {}
            },
            digital_and_technology_transactions: {
                codename: 'digital_and_technology_transactions',
                id: '99b3c04b-e342-4d49-bcf9-36aa53f7d2e2',
                externalId: undefined,
                name: 'Digital and Technology Transactions',
                terms: {}
            },
            digital_health: {
                codename: 'digital_health',
                id: '307e4104-10a2-4f83-aec1-64bf39a8f124',
                externalId: undefined,
                name: 'Digital Health',
                terms: {}
            },
            digital_media_litigation: {
                codename: 'digital_media_litigation',
                id: '942efe98-807d-4fa5-8182-fc5e797fe33e',
                externalId: undefined,
                name: 'Digital Media Litigation',
                terms: {}
            },
            distressed_assets: {
                codename: 'distressed_assets',
                id: '72df8e41-3221-46e3-a1a9-9758f53a2d35',
                externalId: undefined,
                name: 'Distressed Assets',
                terms: {}
            },
            education: {
                codename: 'education',
                id: 'b920fa99-6351-4552-ab7b-36c52a1a0c74',
                externalId: undefined,
                name: 'Education',
                terms: {}
            },
            employment_and_labor: {
                codename: 'employment_and_labor',
                id: '14b68393-c427-4a03-9458-63403c7cbb47',
                externalId: undefined,
                name: 'Employment and Labor',
                terms: {}
            },
            entertainment_litigation: {
                codename: 'entertainment_litigation',
                id: 'bd398368-87b0-4aa6-b3eb-1003fbeda587',
                externalId: undefined,
                name: 'Entertainment Litigation',
                terms: {}
            },
            entertainment_transactions___finance__music_: {
                codename: 'entertainment_transactions___finance__music_',
                id: '85c8ae65-b524-4716-83bb-7cc6b7503a9c',
                externalId: undefined,
                name: 'Entertainment Transactions & Finance (Music)',
                terms: {}
            },
            environment: {
                codename: 'environment',
                id: '1e17f4f3-4fb5-4bee-bada-ba1e87fc4251',
                externalId: undefined,
                name: 'Environment',
                terms: {}
            },
            environmental_litigation: {
                codename: 'environmental_litigation',
                id: '7c61a959-974a-4f2c-916a-009e6d107583',
                externalId: undefined,
                name: 'Environmental Litigation',
                terms: {}
            },
            estate_planning_and_personal_representation: {
                codename: 'estate_planning_and_personal_representation',
                id: 'e6f984b9-a17d-46d6-b3d3-a9c9d02cf460',
                externalId: undefined,
                name: 'Estate Planning and Personal Representation',
                terms: {}
            },
            executive_compensation_and_employee_benefits: {
                codename: 'executive_compensation_and_employee_benefits',
                id: 'a397f47c-af15-409e-8fff-db49327c82c4',
                externalId: undefined,
                name: 'Executive Compensation and Employee Benefits',
                terms: {}
            },
            false_claims_act: {
                codename: 'false_claims_act',
                id: 'ea6cab6c-fa0f-4650-9428-ea30a2f61f24',
                externalId: undefined,
                name: 'False Claims Act',
                terms: {}
            },
            family_office: {
                codename: 'family_office',
                id: 'cd0c956e-3f8e-4ed2-9697-b73edb425f13',
                externalId: undefined,
                name: 'Family Office',
                terms: {}
            },
            federal_government_affairs_and_public_policy: {
                codename: 'federal_government_affairs_and_public_policy',
                id: 'c52fc73d-deea-4b55-8871-70cd3aec2bf6',
                externalId: undefined,
                name: 'Federal Government Affairs and Public Policy',
                terms: {}
            },
            financial_services_litigation_and_enforcement: {
                codename: 'financial_services_litigation_and_enforcement',
                id: '3f3c9939-368e-4bbe-8c43-eca975c12d38',
                externalId: undefined,
                name: 'Financial Services Litigation and Enforcement',
                terms: {}
            },
            financial_services_transactions: {
                codename: 'financial_services_transactions',
                id: '47d93386-91d5-4883-a59f-2065f7105292',
                externalId: undefined,
                name: 'Financial Services Transactions',
                terms: {}
            },
            fintech: {
                codename: 'fintech',
                id: '2ebaf78c-6db3-4adf-b1ff-ab4f863d3380',
                externalId: undefined,
                name: 'Fintech',
                terms: {}
            },
            gaming: {
                codename: 'gaming',
                id: '8ec2ab68-6210-4c8c-876a-9d66d6189956',
                externalId: undefined,
                name: 'Gaming',
                terms: {}
            },
            global_payments: {
                codename: 'global_payments',
                id: 'd7ab3ec5-8134-477f-9c20-59e0a96a5c9c',
                externalId: undefined,
                name: 'Global Payments',
                terms: {}
            },
            government_and_regulatory: {
                codename: 'government_and_regulatory',
                id: '7b8998e2-bd70-4137-ad4d-041bbd9bd4eb',
                externalId: undefined,
                name: 'Government and Regulatory',
                terms: {}
            },
            government_litigation_and_administrative_law: {
                codename: 'government_litigation_and_administrative_law',
                id: '7022aec9-cae4-48a8-bf73-0176d8756e7d',
                externalId: undefined,
                name: 'Government Litigation and Administrative Law',
                terms: {}
            },
            health_care_consulting_support: {
                codename: 'health_care_consulting_support',
                id: '1237b4a7-7087-42bd-a2fe-d0a72194917d',
                externalId: undefined,
                name: 'Health Care Consulting Support',
                terms: {}
            },
            health_care_litigation: {
                codename: 'health_care_litigation',
                id: '4470fb6c-ffa2-4eb0-a9fa-dfbfa7ea2475',
                externalId: undefined,
                name: 'Health Care Litigation',
                terms: {}
            },
            health_care_transactional___regulatory: {
                codename: 'health_care_transactional___regulatory',
                id: '38b17f9d-61e4-42b7-8429-cd74c4a4890a',
                externalId: undefined,
                name: 'Health Care Transactional & Regulatory',
                terms: {}
            },
            health_care_transactions: {
                codename: 'health_care_transactions',
                id: '8f38f3ae-baf3-4820-83da-c1c4db1e5690',
                externalId: undefined,
                name: 'Health Care Transactions',
                terms: {}
            },
            hospitality_and_leisure: {
                codename: 'hospitality_and_leisure',
                id: 'e572b9c4-4627-4f17-8d5a-8ea6a26f2d54',
                externalId: undefined,
                name: 'Hospitality and Leisure',
                terms: {}
            },
            impact_investing_and_community_development: {
                codename: 'impact_investing_and_community_development',
                id: 'fc6e9325-698b-4c09-95e1-9e5a5dd46817',
                externalId: undefined,
                name: 'Impact Investing and Community Development',
                terms: {}
            },
            information_technology: {
                codename: 'information_technology',
                id: 'cea3fb66-de2b-4ee1-9fc1-6707f806d3ca',
                externalId: undefined,
                name: 'Information Technology',
                terms: {}
            },
            insurance: {
                codename: 'insurance',
                id: 'c33f131c-d109-4bac-b29f-8046942a42d8',
                externalId: undefined,
                name: 'Insurance',
                terms: {}
            },
            intellectual_property_protection_and_enforcement: {
                codename: 'intellectual_property_protection_and_enforcement',
                id: 'a393f7ad-9351-4c9e-89f4-2d978e36e12f',
                externalId: undefined,
                name: 'Intellectual Property Protection and Enforcement',
                terms: {}
            },
            investigations_compliance_and_white_collar_defense: {
                codename: 'investigations_compliance_and_white_collar_defense',
                id: 'b98dad6d-f8b5-478d-83c9-381a3e03e223',
                externalId: undefined,
                name: 'Investigations, Compliance and White Collar Defense',
                terms: {}
            },
            land_use: {
                codename: 'land_use',
                id: 'be89d1e0-86e5-4862-a54e-6ac14ffb8619',
                externalId: undefined,
                name: 'Land Use',
                terms: {}
            },
            library: {
                codename: 'library',
                id: '8584a84f-1f63-4df8-a0ca-7ee4dcf7ab56',
                externalId: undefined,
                name: 'Library',
                terms: {}
            },
            life_sciences: {
                codename: 'life_sciences',
                id: 'ab3df53a-2c23-4f77-b708-aa91f9bfe7c0',
                externalId: undefined,
                name: 'Life Sciences',
                terms: {}
            },
            litigation: {
                codename: 'litigation',
                id: '51765055-82d1-4435-a212-f6afe6097383',
                externalId: undefined,
                name: 'Litigation',
                terms: {}
            },
            los_angeles_government_and_land_use: {
                codename: 'los_angeles_government_and_land_use',
                id: 'c3643d8b-8089-41a7-8be8-585d83095106',
                externalId: undefined,
                name: 'Los Angeles Government and Land Use',
                terms: {}
            },
            manatt_digital_and_technology: {
                codename: 'manatt_digital_and_technology',
                id: 'bea9cf31-8a3d-4899-bed1-c0b353321fdd',
                externalId: undefined,
                name: 'Manatt Digital and Technology',
                terms: {}
            },
            manatt_energy_and_environment: {
                codename: 'manatt_energy_and_environment',
                id: 'bf30b53c-47f5-4c6f-a0c9-31ba30ebc708',
                externalId: undefined,
                name: 'Manatt Energy and Environment',
                terms: {}
            },
            manatt_entertainment: {
                codename: 'manatt_entertainment',
                id: '19bc15ae-f5c3-43c4-b71c-037b33de5ca3',
                externalId: undefined,
                name: 'Manatt Entertainment',
                terms: {}
            },
            manatt_financial_services: {
                codename: 'manatt_financial_services',
                id: '68f5d274-c9ed-4914-b039-81f5af1a2aa1',
                externalId: undefined,
                name: 'Manatt Financial Services',
                terms: {}
            },
            manatt_for_entrepreneurs: {
                codename: 'manatt_for_entrepreneurs',
                id: 'c720cd58-c5d3-4850-902d-0ff0bbeb8862',
                externalId: undefined,
                name: 'Manatt for Entrepreneurs',
                terms: {}
            },
            manatt_health: {
                codename: 'manatt_health',
                id: 'bc9a82c6-817e-479b-9244-f7d42f9a84b6',
                externalId: undefined,
                name: 'Manatt Health',
                terms: {}
            },
            manatt_housing_solutions: {
                codename: 'manatt_housing_solutions',
                id: '0c3fc3d5-3a00-49d1-99c5-d70d6910040b',
                externalId: undefined,
                name: 'Manatt Housing Solutions',
                terms: {}
            },
            manatt_leasing: {
                codename: 'manatt_leasing',
                id: 'e167eec0-2baa-4702-bee2-79f3511e9281',
                externalId: undefined,
                name: 'Manatt Leasing',
                terms: {}
            },
            manatt_real_estate: {
                codename: 'manatt_real_estate',
                id: 'ae5d5cb2-6593-49f0-873e-644b2e49b75f',
                externalId: undefined,
                name: 'Manatt Real Estate',
                terms: {}
            },
            manatt_real_estate_advisors: {
                codename: 'manatt_real_estate_advisors',
                id: '4521118b-5a64-488d-83bb-08cd99b79f29',
                externalId: undefined,
                name: 'Manatt Real Estate Advisors',
                terms: {}
            },
            manatt_retail_and_consumer_products: {
                codename: 'manatt_retail_and_consumer_products',
                id: '539f7a83-0abe-48d8-9630-20ea36ef5dcc',
                externalId: undefined,
                name: 'Manatt Retail and Consumer Products',
                terms: {}
            },
            manatts_recovery_team: {
                codename: 'manatts_recovery_team',
                id: '0331143e-ca76-4bab-8acc-4504be271eff',
                externalId: undefined,
                name: "Manatt's Recovery Team",
                terms: {}
            },
            marketing: {
                codename: 'marketing',
                id: '1c7e9cef-96ee-4626-9843-5bffb83c97d1',
                externalId: undefined,
                name: 'Marketing',
                terms: {}
            },
            marketing_and_business_development: {
                codename: 'marketing_and_business_development',
                id: '37c23aa9-bee3-4fe1-af8f-b3e4ca2af7a7',
                externalId: undefined,
                name: 'Marketing and Business Development',
                terms: {}
            },
            mergers_and_acquisitions: {
                codename: 'mergers_and_acquisitions',
                id: 'ee5f21eb-513c-4946-80d8-253140676a0e',
                externalId: undefined,
                name: 'Mergers and Acquisitions',
                terms: {}
            },
            metaverse: {
                codename: 'metaverse',
                id: 'afaa742a-c9f6-4fac-9309-2b3ae1fbdbdb',
                externalId: undefined,
                name: 'Metaverse',
                terms: {}
            },
            mortgage_banking: {
                codename: 'mortgage_banking',
                id: '46eff712-2c4d-4f68-b18f-a4d74ccd8eac',
                externalId: undefined,
                name: 'Mortgage Banking',
                terms: {}
            },
            music: {
                codename: 'music',
                id: '5985276e-4139-4392-8954-e949b761c7b5',
                externalId: undefined,
                name: 'Music',
                terms: {}
            },
            name_image_likeness_nil_in_college_athletics: {
                codename: 'name_image_likeness_nil_in_college_athletics',
                id: 'ffb88ba0-fdfd-4e2a-bd05-2a0904a9a658',
                externalId: undefined,
                name: 'Name Image Likeness (NIL) in College Athletics',
                terms: {}
            },
            national_advertising_division: {
                codename: 'national_advertising_division',
                id: 'de356cb6-24b5-488e-b7ec-25504eb05142',
                externalId: undefined,
                name: 'National Advertising Division',
                terms: {}
            },
            new_york_city_government_policy_and_government_contracts: {
                codename: 'new_york_city_government_policy_and_government_contracts',
                id: '58bacf21-8557-4d55-9c42-5409f6f23793',
                externalId: undefined,
                name: 'New York City Government, Policy and Government Contracts',
                terms: {}
            },
            new_york_state_government_regulatory_policy_and_government_contracts: {
                codename: 'new_york_state_government_regulatory_policy_and_government_contracts',
                id: 'f4807ced-9928-434f-a1dd-187bf72fc831',
                externalId: undefined,
                name: 'New York State Government, Regulatory Policy and Government Contracts',
                terms: {}
            },
            office_administration: {
                codename: 'office_administration',
                id: 'bbd70d23-737a-4d0d-b574-df4bf397f904',
                externalId: undefined,
                name: 'Office Administration',
                terms: {}
            },
            political_advertising: {
                codename: 'political_advertising',
                id: '94512113-63a0-4b4b-b36f-aacb659679d7',
                externalId: undefined,
                name: 'Political Advertising',
                terms: {}
            },
            privacy_and_data_security: {
                codename: 'privacy_and_data_security',
                id: '48802fd7-93ee-466a-8ebd-6e381ed4f99d',
                externalId: undefined,
                name: 'Privacy and Data Security',
                terms: {}
            },
            private_equity_and_buyout: {
                codename: 'private_equity_and_buyout',
                id: 'a19b1e1e-796b-40fc-97af-1e32c3a8cc3c',
                externalId: undefined,
                name: 'Private Equity and Buyout',
                terms: {}
            },
            private_wealth_and_fiduciary_disputes: {
                codename: 'private_wealth_and_fiduciary_disputes',
                id: 'df61548b-8b5f-483e-a302-a461d1b7e8a6',
                externalId: undefined,
                name: 'Private Wealth and Fiduciary Disputes',
                terms: {}
            },
            real_estate_litigation: {
                codename: 'real_estate_litigation',
                id: 'af2ddb29-2006-4415-b8e9-d254e93a37c7',
                externalId: undefined,
                name: 'Real Estate Litigation',
                terms: {}
            },
            real_estate_transactions_and_finance: {
                codename: 'real_estate_transactions_and_finance',
                id: '3e2a6fcf-0c15-4b42-a868-cbcda31390de',
                externalId: undefined,
                name: 'Real Estate Transactions and Finance',
                terms: {}
            },
            risk_management: {
                codename: 'risk_management',
                id: '4a2a1f42-ca96-4e3e-bc3a-01f4821b9a98',
                externalId: undefined,
                name: 'Risk Management',
                terms: {}
            },
            special_purpose_acquisition_companies_spacs: {
                codename: 'special_purpose_acquisition_companies_spacs',
                id: '4ada7d1a-5ae1-4b16-be21-66462a74985b',
                externalId: undefined,
                name: 'Special Purpose Acquisition Companies (SPACs)',
                terms: {}
            },
            tax: {
                codename: 'tax',
                id: '65416d63-dad3-4eb7-b770-e17bee4094d4',
                externalId: undefined,
                name: 'Tax',
                terms: {}
            },
            tcpa_compliance_and_class_action_defense: {
                codename: 'tcpa_compliance_and_class_action_defense',
                id: '622f2334-9227-4bfe-9eb1-f2841444855a',
                externalId: undefined,
                name: 'TCPA Compliance and Class Action Defense',
                terms: {}
            },
            unmanned_aircraft: {
                codename: 'unmanned_aircraft',
                id: '59ddfdf0-7dbb-4d71-b8b8-b90d157460a6',
                externalId: undefined,
                name: 'Unmanned Aircraft',
                terms: {}
            },
            venture_capital__emerging_companies: {
                codename: 'venture_capital__emerging_companies',
                id: 'f7f63092-e7a5-4285-af3d-448a8db649fb',
                externalId: undefined,
                name: 'Venture Capital / Emerging Companies',
                terms: {}
            }
        }
    },

    /**
     * Insight Category
     */
    insight_category: {
        codename: 'insight_category',
        id: '45a6a364-b054-4355-a398-85b0008183a0',
        externalId: undefined,
        name: 'Insight Category',
        terms: {
            articles: {
                codename: 'articles',
                id: '405b43f5-2c39-42c6-b70f-87b5b5e06be8',
                externalId: undefined,
                name: 'Articles',
                terms: {}
            },
            awards: {
                codename: 'awards',
                id: '1992a1aa-a6d3-4159-8fb2-43e8a946f065',
                externalId: undefined,
                name: 'Awards',
                terms: {}
            },
            events: {
                codename: 'events',
                id: '33442a6d-87bb-4170-9b13-9511b91891cf',
                externalId: undefined,
                name: 'Events',
                terms: {}
            },
            insight: {
                codename: 'insight',
                id: '744a7e15-6a4a-4022-b716-8c5ea99206f6',
                externalId: undefined,
                name: 'Insight',
                terms: {}
            },
            news: {
                codename: 'news',
                id: 'c952919b-ffd4-41a4-9e75-e48f55a16b58',
                externalId: undefined,
                name: 'News',
                terms: {}
            },
            newsletters: {
                codename: 'newsletters',
                id: '9708837e-5c34-4716-80cf-e20ad06895d3',
                externalId: undefined,
                name: 'Newsletters',
                terms: {}
            },
            podcasts: {
                codename: 'podcasts',
                id: '5e70a7a6-182e-4ac9-95ce-88c28f40d752',
                externalId: undefined,
                name: 'Podcasts',
                terms: {}
            },
            press_releases: {
                codename: 'press_releases',
                id: '4890ca9b-fc7b-4665-8990-8181c7a49949',
                externalId: undefined,
                name: 'Press Releases',
                terms: {}
            },
            webinars: {
                codename: 'webinars',
                id: '7f4c5c3c-f1b0-469c-9737-1c731523c9e1',
                externalId: undefined,
                name: 'Webinars',
                terms: {}
            },
            white_papers: {
                codename: 'white_papers',
                id: 'f50d6dfd-cbd9-4b29-a60f-75a2d9d34508',
                externalId: undefined,
                name: 'White Papers',
                terms: {}
            }
        }
    },

    /**
     * Insights Year
     */
    insights_year: {
        codename: 'insights_year',
        id: '7a8b9853-26a3-4cdd-a3b2-52c3276a8907',
        externalId: undefined,
        name: 'Insights Year',
        terms: {
            n2000: {
                codename: 'n2000',
                id: '19f1bade-02fd-4692-8298-cbf5db8a75e9',
                externalId: undefined,
                name: '2000',
                terms: {}
            },
            n2001: {
                codename: 'n2001',
                id: '5ca15e8f-c5b6-4dc8-9cc9-0796f1848e09',
                externalId: undefined,
                name: '2001',
                terms: {}
            },
            n2002: {
                codename: 'n2002',
                id: '5891fa1b-303c-4465-9501-62abac89dc06',
                externalId: undefined,
                name: '2002',
                terms: {}
            },
            n2003: {
                codename: 'n2003',
                id: '1c076cee-b7cb-4161-8228-2456de89f978',
                externalId: undefined,
                name: '2003',
                terms: {}
            },
            n2004: {
                codename: 'n2004',
                id: 'c9655dfa-248e-44cf-84cf-e36cd51157f4',
                externalId: undefined,
                name: '2004',
                terms: {}
            },
            n2005: {
                codename: 'n2005',
                id: '063d88b5-2464-4e19-a242-646fcb081f96',
                externalId: undefined,
                name: '2005',
                terms: {}
            },
            n2006: {
                codename: 'n2006',
                id: 'eb9512a0-25cd-49c6-96e4-7b1c050cdc23',
                externalId: undefined,
                name: '2006',
                terms: {}
            },
            n2007: {
                codename: 'n2007',
                id: '6bebba8b-6fe1-4984-ba1b-7a713930b1e3',
                externalId: undefined,
                name: '2007',
                terms: {}
            },
            n2008: {
                codename: 'n2008',
                id: 'b77c9335-ba06-44a5-a946-7293ae4125b5',
                externalId: undefined,
                name: '2008',
                terms: {}
            },
            n2009: {
                codename: 'n2009',
                id: '0812c4f4-fa1d-4460-a0b8-ad0515d6ade8',
                externalId: undefined,
                name: '2009',
                terms: {}
            },
            n2010: {
                codename: 'n2010',
                id: '10d94e7f-c335-4139-a81d-16226b43bc56',
                externalId: undefined,
                name: '2010',
                terms: {}
            },
            n2011: {
                codename: 'n2011',
                id: '2791c23d-20b8-406a-94cc-7e128b0cb723',
                externalId: undefined,
                name: '2011',
                terms: {}
            },
            n2012: {
                codename: 'n2012',
                id: '20a2b097-1e86-4583-89c8-c6986e90a3cb',
                externalId: undefined,
                name: '2012',
                terms: {}
            },
            n2013: {
                codename: 'n2013',
                id: '30173ef7-e945-4d96-b2d8-3430e4744bea',
                externalId: undefined,
                name: '2013',
                terms: {}
            },
            n2014: {
                codename: 'n2014',
                id: 'd525004b-3eb3-4e59-a91a-64600766b0e7',
                externalId: undefined,
                name: '2014',
                terms: {}
            },
            n2015: {
                codename: 'n2015',
                id: '746fac65-a39e-4795-afe3-3b0a418dac94',
                externalId: undefined,
                name: '2015',
                terms: {}
            },
            n2016: {
                codename: 'n2016',
                id: '594dba92-072f-4638-92b3-575cb1779cec',
                externalId: undefined,
                name: '2016',
                terms: {}
            },
            n2017: {
                codename: 'n2017',
                id: '338c2afc-e71f-4679-b3a1-a1f007fffc5f',
                externalId: undefined,
                name: '2017',
                terms: {}
            },
            n2018: {
                codename: 'n2018',
                id: '2b9ea379-6e1b-4296-b3f3-4346327df7e5',
                externalId: undefined,
                name: '2018',
                terms: {}
            },
            n2019: {
                codename: 'n2019',
                id: 'b083b5fb-3b6f-4f29-84ef-f82b0f7ff679',
                externalId: undefined,
                name: '2019',
                terms: {}
            },
            n2020: {
                codename: 'n2020',
                id: '7972d435-307c-4726-8d15-a6ec44a1a74c',
                externalId: undefined,
                name: '2020',
                terms: {}
            },
            n2021: {
                codename: 'n2021',
                id: '32543c1c-1dd5-4e06-abf4-6cbc210f2309',
                externalId: undefined,
                name: '2021',
                terms: {}
            },
            n2022: {
                codename: 'n2022',
                id: 'b0f56a16-f711-4bbe-b06a-11dc31e79243',
                externalId: undefined,
                name: '2022',
                terms: {}
            },
            n2023: {
                codename: 'n2023',
                id: 'ae870694-167b-4327-80bf-7009661500ad',
                externalId: undefined,
                name: '2023',
                terms: {}
            },
            n2024: {
                codename: 'n2024',
                id: '12b0da73-b05a-4739-8799-0ea3ad74e9b8',
                externalId: undefined,
                name: '2024',
                terms: {}
            },
            n2025: {
                codename: 'n2025',
                id: 'ae5ae860-e917-4072-a05f-cac389ef45f6',
                externalId: undefined,
                name: '2025',
                terms: {}
            }
        }
    },

    /**
     * Subscription Categories
     */
    subscription_categories: {
        codename: 'subscription_categories',
        id: '6f27851b-ce4f-4fa3-93cf-fa29a8890d68',
        externalId: undefined,
        name: 'Subscription Categories',
        terms: {
            advertising_law: {
                codename: 'advertising_law',
                id: '7545abd3-7861-4a1c-906b-ffac1fd1d161',
                externalId: undefined,
                name: 'Advertising Law',
                terms: {}
            },
            antitrust_law: {
                codename: 'antitrust_law',
                id: 'bddb6e7c-0499-4dab-8bbf-b6f3ac3e0a38',
                externalId: undefined,
                name: 'Antitrust Law',
                terms: {}
            },
            appellate_law: {
                codename: 'appellate_law',
                id: '24f49db8-1af3-4b8e-9aa5-ea27adbe7b30',
                externalId: undefined,
                name: 'Appellate Law',
                terms: {}
            },
            ca_health_and_government_covid_19_guidance__week_in_review: {
                codename: 'ca_health_and_government_covid_19_guidance__week_in_review',
                id: '8149b542-b4f1-4d65-852b-55bd04e50c4b',
                externalId: undefined,
                name: 'CA Health and Government COVID-19 Guidance: Week in Review',
                terms: {}
            },
            california_government_update: {
                codename: 'california_government_update',
                id: 'ebc2d06d-aa74-4b9e-b17a-94da9263e73f',
                externalId: undefined,
                name: 'California Government Update',
                terms: {}
            },
            client_alert: {
                codename: 'client_alert',
                id: '65b00324-a1b8-4043-a17f-5ac18aab8437',
                externalId: undefined,
                name: 'Client Alert',
                terms: {}
            },
            climate_change: {
                codename: 'climate_change',
                id: 'b1a3df74-eb84-4b5e-8792-ca50dca851c9',
                externalId: undefined,
                name: 'Climate Change',
                terms: {}
            },
            consumer_financial_services_law: {
                codename: 'consumer_financial_services_law',
                id: '7c39985b-1cd6-4bfd-837d-3a2dfc2ae6ca',
                externalId: undefined,
                name: 'Consumer Financial Services Law',
                terms: {}
            },
            covid_19_update: {
                codename: 'covid_19_update',
                id: 'c6b0a6b4-f4cf-4156-bb8e-2b01027f1418',
                externalId: undefined,
                name: 'COVID-19 Update',
                terms: {}
            },
            cross_border_transactions: {
                codename: 'cross_border_transactions',
                id: 'b243dcc8-73eb-42c4-b70a-7ee7422ff653',
                externalId: undefined,
                name: 'Cross Border Transactions',
                terms: {}
            },
            employment_law: {
                codename: 'employment_law',
                id: 'c224b8f9-cb71-4bd0-a4f1-e9ccb172851c',
                externalId: undefined,
                name: 'Employment Law',
                terms: {}
            },
            energy_law: {
                codename: 'energy_law',
                id: '4225c4c8-2e69-4be2-bc20-c626fcb6df45',
                externalId: undefined,
                name: 'Energy Law',
                terms: {}
            },
            entertainment_quarterly_roundup: {
                codename: 'entertainment_quarterly_roundup',
                id: 'f60abe83-95a7-4ef6-b9bf-da6242492b10',
                externalId: undefined,
                name: 'Entertainment Quarterly Roundup',
                terms: {}
            },
            environmental_law: {
                codename: 'environmental_law',
                id: '8a339982-c310-4d62-9222-b4b8195b9ee2',
                externalId: undefined,
                name: 'Environmental Law',
                terms: {}
            },
            estate_tax_law: {
                codename: 'estate_tax_law',
                id: '7d6b46a9-561c-4620-ae43-fc7366d1b78f',
                externalId: undefined,
                name: 'Estate Tax Law',
                terms: {}
            },
            financial_services_law: {
                codename: 'financial_services_law',
                id: '788428bb-c555-40f5-9fb4-b2e9d568f926',
                externalId: undefined,
                name: 'Financial Services Law',
                terms: {}
            },
            health_highlights: {
                codename: 'health_highlights',
                id: 'e9620bf4-0eb2-4c5f-bd0a-48496ab07fb9',
                externalId: undefined,
                name: 'Health Highlights',
                terms: {}
            },
            healthcare_litigation: {
                codename: 'healthcare_litigation',
                id: '6a99b377-2be3-41c7-90af-8434ec494d24',
                externalId: undefined,
                name: 'Healthcare Litigation',
                terms: {}
            },
            insurance_law: {
                codename: 'insurance_law',
                id: '2139e488-8dc2-48ed-8de4-d59fef698266',
                externalId: undefined,
                name: 'Insurance Law',
                terms: {}
            },
            intellectual_property_law: {
                codename: 'intellectual_property_law',
                id: '1001cddb-f15f-4387-8077-a37d66fe7fd7',
                externalId: undefined,
                name: 'Intellectual Property Law',
                terms: {}
            },
            investigations_and_white_collar_defense: {
                codename: 'investigations_and_white_collar_defense',
                id: 'a8d88368-e2db-4a6b-b561-00348e47b308',
                externalId: undefined,
                name: 'Investigations and White Collar Defense',
                terms: {}
            },
            m_a_law: {
                codename: 'm_a_law',
                id: 'cf8a8f6e-b1bc-48e2-a619-b00af8b0c901',
                externalId: undefined,
                name: 'M&A Law',
                terms: {}
            },
            manatt_digital_and_technology: {
                codename: 'manatt_digital_and_technology',
                id: 'fc2d2f28-7133-4343-88f9-a0963c8a5b21',
                externalId: undefined,
                name: 'Manatt Digital and Technology',
                terms: {}
            },
            manatt_for_entrepreneurs: {
                codename: 'manatt_for_entrepreneurs',
                id: 'f27e7df1-2c8e-4d01-b8fd-7f9d8d8766cf',
                externalId: undefined,
                name: 'Manatt for Entrepreneurs',
                terms: {}
            },
            manatt_on_1332: {
                codename: 'manatt_on_1332',
                id: 'a1723b6b-7e71-4327-9bbe-7fd00abecc3b',
                externalId: undefined,
                name: 'Manatt on 1332',
                terms: {}
            },
            manatt_on_health: {
                codename: 'manatt_on_health',
                id: '2f4a7919-e8ad-43bb-8965-045faf23e1df',
                externalId: undefined,
                name: 'Manatt on Health',
                terms: {}
            },
            manatt_on_health_reform__weekly_highlights: {
                codename: 'manatt_on_health_reform__weekly_highlights',
                id: '57fe2fcb-833a-464d-a92e-bce03f071aa3',
                externalId: undefined,
                name: 'Manatt on Health Reform: Weekly Highlights',
                terms: {}
            },
            manatt_on_health__medicaid_edition: {
                codename: 'manatt_on_health__medicaid_edition',
                id: '11bdd0ec-8886-4ed5-8e7e-e357251f1597',
                externalId: undefined,
                name: 'Manatt on Health: Medicaid Edition',
                terms: {}
            },
            manatt_on_medicaid__monthly_expansion_recap: {
                codename: 'manatt_on_medicaid__monthly_expansion_recap',
                id: 'bd1b0dd7-4f4b-401f-a518-cd891dcade99',
                externalId: undefined,
                name: 'Manatt on Medicaid: Monthly Expansion Recap',
                terms: {}
            },
            manatt_on_washington: {
                codename: 'manatt_on_washington',
                id: 'e8842313-dda7-443e-bd35-c12910c2afe4',
                externalId: undefined,
                name: 'Manatt on Washington',
                terms: {}
            },
            manatt_real_estate_advisors: {
                codename: 'manatt_real_estate_advisors',
                id: '741c40a6-0840-4580-850f-55d7d19f8440',
                externalId: undefined,
                name: 'Manatt Real Estate Advisors',
                terms: {}
            },
            manatt_unite: {
                codename: 'manatt_unite',
                id: '42095c97-c645-49da-a261-254e064ca6d8',
                externalId: undefined,
                name: 'Manatt Unite',
                terms: {}
            },
            non_profit_law: {
                codename: 'non_profit_law',
                id: '473966ce-8487-403a-8ccb-627bbe9afd6c',
                externalId: undefined,
                name: 'Non-Profit Law',
                terms: {}
            },
            ny_state_government__week_in_review: {
                codename: 'ny_state_government__week_in_review',
                id: '36d1762d-20df-40ff-9448-b38bf9f2952e',
                externalId: undefined,
                name: 'NY State Government: Week in Review',
                terms: {}
            },
            privacy_and_data_security: {
                codename: 'privacy_and_data_security',
                id: '1a85eefc-a48e-4a6b-a202-d26a0fb56cd2',
                externalId: undefined,
                name: 'Privacy and Data Security',
                terms: {}
            },
            real_estate_and_land_use: {
                codename: 'real_estate_and_land_use',
                id: '306c598f-4f59-41a6-a00b-0f5d1e6b01d9',
                externalId: undefined,
                name: 'Real Estate and Land Use',
                terms: {}
            },
            retail_and_consumer_products_law_roundup: {
                codename: 'retail_and_consumer_products_law_roundup',
                id: 'c0400346-bcae-4e68-a162-2e3182623e7b',
                externalId: undefined,
                name: 'Retail and Consumer Products Law Roundup',
                terms: {}
            },
            securities_law: {
                codename: 'securities_law',
                id: 'dd8d7dd6-1834-4cc8-84e2-c0a15a5a0858',
                externalId: undefined,
                name: 'Securities Law',
                terms: {}
            },
            sports_law: {
                codename: 'sports_law',
                id: 'ad11b69b-b9f5-478e-bd4c-1df0175f5123',
                externalId: undefined,
                name: 'Sports Law',
                terms: {}
            },
            tax_law: {
                codename: 'tax_law',
                id: '1ca032ed-7432-4a18-a969-6261f31962d3',
                externalId: undefined,
                name: 'Tax Law',
                terms: {}
            },
            tcpa_connect: {
                codename: 'tcpa_connect',
                id: '1f19de09-e964-48fd-b11f-685f5d8bb0f4',
                externalId: undefined,
                name: 'TCPA Connect',
                terms: {}
            },
            the_manatt_state_cost_containment_update: {
                codename: 'the_manatt_state_cost_containment_update',
                id: '5e6e6c67-5649-4085-bdfa-53c8bd6acae9',
                externalId: undefined,
                name: 'The Manatt State Cost Containment Update',
                terms: {}
            },
            employment_law_tip_of_the_month: {
                codename: 'employment_law_tip_of_the_month',
                id: 'fd08cf74-770e-4821-b4a0-8856ef131450',
                externalId: undefined,
                name: 'Tip of the Month - Employment and Labor Law',
                terms: {}
            }
        }
    },

    /**
     * Title
     */
    title: {
        codename: 'title',
        id: '1f93233f-d1f2-4217-945c-0368e04b4670',
        externalId: undefined,
        name: 'Title',
        terms: {
            advisor: {
                codename: 'advisor',
                id: 'd6a55332-ea41-4e4c-8ea6-de9ff4c85995',
                externalId: undefined,
                name: 'Advisor',
                terms: {}
            },
            analyst: {
                codename: 'analyst',
                id: '3ad4a6af-19ca-4a61-97ac-9aab019c0926',
                externalId: undefined,
                name: 'Analyst',
                terms: {}
            },
            associate: {
                codename: 'associate',
                id: '8b34fc42-821c-4c25-852b-0780130a9e64',
                externalId: undefined,
                name: 'Associate',
                terms: {}
            },
            attorney: {
                codename: 'attorney',
                id: '0150a564-7a04-4169-b353-65f321926588',
                externalId: undefined,
                name: 'Attorney',
                terms: {}
            },
            chief_executive_officer_and_managing_partner: {
                codename: 'chief_executive_officer_and_managing_partner',
                id: 'd3ce4c10-408e-4306-ae92-1420bcaf8f81',
                externalId: undefined,
                name: 'Chief Executive Officer and Managing Partner',
                terms: {}
            },
            chief_operating_officer: {
                codename: 'chief_operating_officer',
                id: '7b7b8cd9-092e-4b79-8aa1-6d84c791178d',
                externalId: undefined,
                name: 'Chief Operating Officer',
                terms: {}
            },
            cofounder_emeritus: {
                codename: 'cofounder_emeritus',
                id: '3dc86f9a-8cee-4120-8132-40f9b52f25e7',
                externalId: undefined,
                name: 'Co-Founder Emeritus',
                terms: {}
            },
            consultant: {
                codename: 'consultant',
                id: '95e2844c-8c78-4bfd-b383-bc38e6cc72e4',
                externalId: undefined,
                name: 'Consultant',
                terms: {}
            },
            counsel: {
                codename: 'counsel',
                id: '404789d4-ae19-40aa-a9be-ace272933da9',
                externalId: undefined,
                name: 'Counsel',
                terms: {}
            },
            counsel_and_director: {
                codename: 'counsel_and_director',
                id: '741291c1-a785-45a2-8145-7e1329fc7382',
                externalId: undefined,
                name: 'Counsel and Director',
                terms: {}
            },
            data_privacy_and_ediscovery_specialist: {
                codename: 'data_privacy_and_ediscovery_specialist',
                id: '8c136ee4-e21c-4cd8-b06d-406c68bbf9aa',
                externalId: undefined,
                name: 'Data Privacy & e-Discovery Specialist',
                terms: {}
            },
            director: {
                codename: 'director',
                id: '484d662a-72fd-4114-b24e-5c6300714356',
                externalId: undefined,
                name: 'Director',
                terms: {}
            },
            federal_and_legislative_analyst: {
                codename: 'federal_and_legislative_analyst',
                id: '94ac5180-ea57-4bde-8c6a-b6e5fdad0438',
                externalId: undefined,
                name: 'Federal and Legislative Analyst',
                terms: {}
            },
            foreign_and_domestic_legal_specialist: {
                codename: 'foreign_and_domestic_legal_specialist',
                id: '97c25f88-fc7d-441e-ae94-05524dc3803b',
                externalId: undefined,
                name: 'Foreign & Domestic Legal Specialist',
                terms: {}
            },
            founder_and_partner: {
                codename: 'founder_and_partner',
                id: '50e114a1-5e79-4de0-9bac-636d0bf681fa',
                externalId: undefined,
                name: 'Founder and Partner',
                terms: {}
            },
            general_counsel: {
                codename: 'general_counsel',
                id: '7f7d18e8-94bc-435f-a057-8bf1563917c5',
                externalId: undefined,
                name: 'General Counsel',
                terms: {}
            },
            head_of_growth_and_operations: {
                codename: 'head_of_growth_and_operations',
                id: '2d6a1e16-ac5c-43e7-8165-8d9712090d57',
                externalId: undefined,
                name: 'Head of Growth and Operations',
                terms: {}
            },
            law_clerk: {
                codename: 'law_clerk',
                id: 'c47737b8-220e-46d0-b81f-6149cf54f87b',
                externalId: undefined,
                name: 'Law Clerk',
                terms: {}
            },
            leader_and_partner: {
                codename: 'leader_and_partner',
                id: '6805601c-298e-4795-ae98-b5e6720b016e',
                externalId: undefined,
                name: 'Leader and Partner',
                terms: {}
            },
            legislative_advisor: {
                codename: 'legislative_advisor',
                id: '640adf8c-a741-450d-9ee1-bd413881280d',
                externalId: undefined,
                name: 'Legislative Advisor',
                terms: {}
            },
            manager: {
                codename: 'manager',
                id: 'f6c38ce7-80ae-4dd0-b16b-b62a144e4857',
                externalId: undefined,
                name: 'Manager',
                terms: {}
            },
            managing_director: {
                codename: 'managing_director',
                id: '667100a1-5142-47d7-9e5b-bd9e89fe16c7',
                externalId: undefined,
                name: 'Managing Director',
                terms: {}
            },
            managing_director_and_partner: {
                codename: 'managing_director_and_partner',
                id: '51e6a35d-df08-4287-92c2-a87fb1d91214',
                externalId: undefined,
                name: 'Managing Director and Partner',
                terms: {}
            },
            national_advisor: {
                codename: 'national_advisor',
                id: '846e6314-9e23-4fe4-8fab-67e4dddd2993',
                externalId: undefined,
                name: 'National Advisor',
                terms: {}
            },
            partner: {
                codename: 'partner',
                id: '7f78c37c-9eb3-44b6-8880-afd574260ef2',
                externalId: undefined,
                name: 'Partner',
                terms: {}
            },
            partner_and_director_of_pro_bono: {
                codename: 'partner_and_director_of_pro_bono',
                id: '98c3d229-3dd8-44c6-bad9-a914cb974f1c',
                externalId: undefined,
                name: 'Partner and Director of Pro Bono',
                terms: {}
            },
            partner_and_senior_advisor: {
                codename: 'partner_and_senior_advisor',
                id: 'dcffa7d4-0b0d-4aa6-9c93-7cb6ee4e9f6a',
                externalId: undefined,
                name: 'Partner and Senior Advisor',
                terms: {}
            },
            patent_agent: {
                codename: 'patent_agent',
                id: '779144f3-c0bb-4dd3-9751-945e89e55ba6',
                externalId: undefined,
                name: 'Patent Agent',
                terms: {}
            },
            pro_bono_attorney: {
                codename: 'pro_bono_attorney',
                id: '0b54bad9-3cad-4454-8107-6e14136b12de',
                externalId: undefined,
                name: 'Pro Bono Attorney',
                terms: {}
            },
            pro_bono_manager: {
                codename: 'pro_bono_manager',
                id: '43b15263-b999-4424-a4b0-c5938132c6c5',
                externalId: undefined,
                name: 'Pro Bono Manager',
                terms: {}
            },
            senior_advisor: {
                codename: 'senior_advisor',
                id: 'af111755-8dc7-4a5e-a039-fa0bcfbeec50',
                externalId: undefined,
                name: 'Senior Advisor',
                terms: {}
            },
            senior_analyst: {
                codename: 'senior_analyst',
                id: '2763de86-6033-4362-bf2d-fb8c980ac292',
                externalId: undefined,
                name: 'Senior Analyst',
                terms: {}
            },
            senior_attorney: {
                codename: 'senior_attorney',
                id: 'ebb15a72-2795-4824-8260-038f61163c0f',
                externalId: undefined,
                name: 'Senior Attorney',
                terms: {}
            },
            senior_counsel: {
                codename: 'senior_counsel',
                id: '9b7ae21c-5a46-4aaf-8936-72fe2bef44a0',
                externalId: undefined,
                name: 'Senior Counsel',
                terms: {}
            },
            senior_land_use_planner: {
                codename: 'senior_land_use_planner',
                id: 'a7509c23-2790-485f-8a38-f61eb656ef1c',
                externalId: undefined,
                name: 'Senior Land Use Planner',
                terms: {}
            },
            senior_legislative_analyst: {
                codename: 'senior_legislative_analyst',
                id: '8b2f350f-75ed-490d-9743-9e8db5f0f693',
                externalId: undefined,
                name: 'Senior Legislative Analyst',
                terms: {}
            },
            senior_manager: {
                codename: 'senior_manager',
                id: '68f8d81b-d21b-48c2-9aa1-c1ba90d224a7',
                externalId: undefined,
                name: 'Senior Manager',
                terms: {}
            },
            senior_managing_director: {
                codename: 'senior_managing_director',
                id: 'ae20205c-ea4b-42c1-b86b-ac83a662af50',
                externalId: undefined,
                name: 'Senior Managing Director',
                terms: {}
            },
            senior_partner: {
                codename: 'senior_partner',
                id: '5c07301b-2c30-4bd0-8d3e-edb5c9866e4d',
                externalId: undefined,
                name: 'Senior Partner',
                terms: {}
            },
            special_counsel: {
                codename: 'special_counsel',
                id: '66abc8da-3938-4034-8bc1-63a930f42afa',
                externalId: undefined,
                name: 'Special Counsel',
                terms: {}
            },
            staff_attorney: {
                codename: 'staff_attorney',
                id: '0b8e9fa3-d7b5-44ef-a396-ddbcbb9d4204',
                externalId: undefined,
                name: 'Staff Attorney',
                terms: {}
            }
        }
    },

    /**
     * Ventures Industries
     */
    ventures_industries: {
        codename: 'ventures_industries',
        id: '2ab15f11-4d84-5c22-8822-a07d13628c34',
        externalId: 'taxonomy_ventures_industries',
        name: 'Ventures Industries',
        terms: {
            artificialintelligence: {
                codename: 'artificialintelligence',
                id: '0778f4e3-8b95-54a8-9da3-46ed35846952',
                externalId: 'taxonomy_term_artificialintelligence',
                name: 'Artificial Intelligence',
                terms: {}
            },
            climate: {
                codename: 'climate',
                id: '3d24d364-8ff2-5903-a4ad-be100865bb6f',
                externalId: 'taxonomy_term_climate',
                name: 'Climate',
                terms: {}
            },
            digitalhealth: {
                codename: 'digitalhealth',
                id: 'b263fa51-7847-5b30-ac67-b03dba965ba0',
                externalId: 'taxonomy_term_digitalhealth',
                name: 'Digital Health',
                terms: {}
            },
            fintech: {
                codename: 'fintech',
                id: '67eaba5d-2d60-5496-b7e7-99a32469caca',
                externalId: 'taxonomy_term_fintech',
                name: 'Fintech, Blockchain and Web3',
                terms: {}
            },
            digitalmediaentertainment: {
                codename: 'digitalmediaentertainment',
                id: 'b24b896e-ea47-58bf-b29d-b5ef2d48d680',
                externalId: 'taxonomy_term_digitalmediaentertainment',
                name: 'Media and Entertainment',
                terms: {}
            }
        }
    },

    /**
     * Ventures Services
     */
    ventures_services: {
        codename: 'ventures_services',
        id: '26a1be39-fddc-5d83-bb47-bdaa1958b3ff',
        externalId: 'taxonomy_ventures_services',
        name: 'Ventures Services',
        terms: {
            advertise: {
                codename: 'advertise',
                id: '6941110f-efb2-5bb5-966d-fe12e9ecf3a8',
                externalId: 'taxonomy_term_advertise',
                name: 'Advertise',
                terms: {}
            },
            establishyourteam: {
                codename: 'establishyourteam',
                id: 'a61727e8-622b-5b72-a430-05c32d27cee2',
                externalId: 'taxonomy_term_establishyourteam',
                name: 'Build Your Team',
                terms: {}
            },
            commercializeyourproducts: {
                codename: 'commercializeyourproducts',
                id: '81d7e2b4-0436-5e7d-a5c4-8dc2efe44309',
                externalId: 'taxonomy_term_commercializeyourproducts',
                name: 'Commercialize Your Products',
                terms: {}
            },
            raisemoney: {
                codename: 'raisemoney',
                id: '808ac420-88f2-5c38-a52a-4122e56ef2f5',
                externalId: 'taxonomy_term_raisemoney',
                name: 'Finance Your Company',
                terms: {}
            },
            launch: {
                codename: 'launch',
                id: '2a7d4577-4610-5271-9cb8-90b3e110ca6d',
                externalId: 'taxonomy_term_launch',
                name: 'Launch',
                terms: {}
            },
            protectyourip: {
                codename: 'protectyourip',
                id: '96bb4982-e7af-5e7b-9bbc-63a6a5d6efea',
                externalId: 'taxonomy_term_protectyourip',
                name: 'Protect Your IP',
                terms: {}
            },
            scale: {
                codename: 'scale',
                id: '14148020-040f-5067-a578-dfe9c89650fa',
                externalId: 'taxonomy_term_scale',
                name: 'Scale',
                terms: {}
            },
            secureyourdata: {
                codename: 'secureyourdata',
                id: 'c473e165-6ff3-518c-8a82-69c356a65654',
                externalId: 'taxonomy_term_secureyourdata',
                name: 'Secure Your Data',
                terms: {}
            },
            transact: {
                codename: 'transact',
                id: '5f2ce100-19d3-5c34-9ac0-e31ce5c0ad4e',
                externalId: 'taxonomy_term_transact',
                name: 'Transact',
                terms: {}
            }
        }
    }
} as const;
