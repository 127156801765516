// stores/pardot-modal.ts
import { defineStore } from 'pinia'

interface ModalState {
  openAsModal: boolean
  items: string[]
  email: string
}

export const usePardotModalStore = defineStore('pardotModal', {
  state: (): ModalState => ({
    openAsModal: false,
    items: [],
    email: ''
  }),
  actions: {
    toggleModal() {
      this.openAsModal = !this.openAsModal
    },
    setCloseViewAll() {
      this.openAsModal = false
    },
    setItems(items: string[]) {
      this.items = items
    },
    setEmail(email: string) {
      this.email = email
    }
  }
})
